import {
  AspectRatio,
  Box,
  Divider,
  Flex,
  HStack,
  Image,
  Text
} from "@chakra-ui/react";
import Banner from "../../_assets/banner.png";

import { TextGray } from "../../components/Atomos/Texts/TextGray";
import { RiArrowRightLine } from "react-icons/ri";
import { Link } from "react-router-dom";

export const Detalhe = () => {
  return (
    <>
      <Box w="full" px={{ base: "6", md: "8" }} pb="8">
        <Flex
          w="full"
          direction={{ base: "column", md: "row" }}
          maxW="1200px"
          margin="0 auto"
          position="relative"
        >
          <Flex
            flex="1"
            pr="8"
            direction="column"
            align="center"
            pb={{ base: "12", md: "0" }}
          >
            <Text
              fontWeight="bold"
              fontSize={{
                base: "3rem",
                sm: "3rem",
                md: "3.5rem"
              }}
              lineHeight="1.2"
            >
              Consulte todas as informações do seu município
            </Text>
            <HStack
              alignItems="baseline"
              position="absolute"
              bottom="50px"
              left={{ base: "10px", md: "100px" }}
              bgColor="white"
              divider={<Divider orientation="vertical" />}
              spacing="5"
              boxShadow="0px 0px 20px 0px rgb(0 0 0 / 16%)"
              px="10"
              py="4"
              borderRadius="lg"
              zIndex="1"
            >
              <Box as={Link} to="/mapa" data-group>
                <Flex align="center">
                  <Text
                    fontWeight="bold"
                    mr="1"
                    transition="0.3s"
                    fontSize="lg"
                    _groupHover={{ marginRight: "30px" }}
                  >
                    Ir para Mapa
                  </Text>
                  <RiArrowRightLine />
                </Flex>
                <TextGray fontSize="sm">
                  Veja o mapa completo da sua cidade
                </TextGray>
              </Box>
              <Box
                as="a"
                href={process.env.REACT_APP_FRONTEND_INTERNAL}
                data-group
              >
                <Flex align="center">
                  <Text
                    fontWeight="bold"
                    mr="1"
                    transition="0.3s"
                    fontSize="lg"
                    _groupHover={{ marginRight: "30px" }}
                  >
                    Ir para área interna
                  </Text>
                  <RiArrowRightLine />
                </Flex>
                <TextGray fontSize="sm">
                  Acesse a área interna para colaboradores
                </TextGray>
              </Box>
            </HStack>
          </Flex>
          <Flex flex="1" justifyContent="end">
            <AspectRatio
              minH="400px"
              maxW={{ base: "400px", md: "100%" }}
              w="full"
              h="full"
              ratio={4 / 3}
            >
              <Image
                src={Banner}
                borderRadius="2xl"
                alt="Imagem de uma cidade"
                zIndex="0"
              />
            </AspectRatio>
          </Flex>
        </Flex>
      </Box>
    </>
  );
};
